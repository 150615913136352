.player {
  width: 400px;
  max-width: 80vw;
  background-color: #222;
  padding: 25px 10px;
  border-radius: 25px;
}


.sound-reset-button {
  background-color: rgb(167, 0, 50) !important;
}

.sound-reset-button:hover {
  background-color: rgba(167, 0, 50, 0.8) !important;
}

.sound-button.active {
  background-color: rgb(0, 170, 255);
}

.sound-button.active:hover {
  background-color: rgba(0, 170, 255, 0.8);
}



.MuiPaper-root {
  background-color: transparent !important;
  margin: 0 !important;
}

.MuiListItem-root {
  min-width: 120px;
}

.MuiMenuItem-root {
  min-height: 40px !important;
}
