.image-container {
  position: relative;
}

.play-button {
  width: 5vmin;
  height: 5vmin;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(239deg) brightness(107%) contrast(101%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.song-image {
  height: 30vmin;
  min-height: 30vh;
}

.play-button:hover,
.song-image:hover{
  cursor:pointer; 
  opacity:0.85;
}

@media (prefers-reduced-motion: no-preference) {
  .song-image {
    animation: song-image-spin infinite 20s linear;
  }
  .song-image.paused {
    animation-play-state: paused !important;
  }
}


@keyframes song-image-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}