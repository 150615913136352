body {
  background-color: #282c34;

}

.App {
  height: 80vh;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}